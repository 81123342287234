import { Grid } from '@mui/material';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  useEditController,
  BooleanInput,
  Toolbar,
  SaveButton,
  FormDataConsumer,
} from 'react-admin';

const BinEdit = () => {
  const { save } = useEditController();

  const handleSubmit = async (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();

    console.log(data);

    save({
      cardType: data.cardType,
      cardBankType: data.cardBankType,
      cardProviderId: data.cardProviderId,
      bankId: data.bankId,
      approved: data.approved,
    });
  };

  return (
    <Edit>
      <Grid container>
        <Grid item xs={12} md={4}>
          <SimpleForm
            onSubmit={handleSubmit}
            toolbar={<UserEditToolbar />}
            sx={{
              '& .MuiFormControl-root': { width: '100%', minWidth: '300px' },
              '& .ra-input': { width: '100%' },
            }}
          >
            <TextInput source='bin' validate={[required()]} disabled />
            <ReferenceInput source='cardType' reference='cardTypes'>
              <SelectInput optionText='name' validate={[required()]} />
            </ReferenceInput>
            <FormDataConsumer>
              {({ formData }) => (
                <ReferenceInput
                  source='cardBankType'
                  reference='cardBankTypes'
                  filter={{ cardTypeId: formData.cardType }}
                  key={formData.cardTypeId}
                >
                  <SelectInput
                    optionText='name'
                    validate={[required()]}
                    disabled={!formData.cardType}
                  />
                </ReferenceInput>
              )}
            </FormDataConsumer>
            <ReferenceInput source='cardProviderId' reference='cardProviders'>
              <SelectInput
                optionText='name'
                validate={[required()]}
                optionValue='dbId'
              />
            </ReferenceInput>
            <ReferenceInput source='bankId' reference='banks'>
              <SelectInput
                optionText='name'
                validate={[required()]}
                optionValue='dbId'
              />
            </ReferenceInput>
            <BooleanInput source='approved' />
          </SimpleForm>
        </Grid>
      </Grid>
    </Edit>
  );
};

const UserEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export default BinEdit;
