import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import {
  AutocompleteInput,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  ReferenceInput,
  SavedQueriesList,
  SelectInput,
  Form,
  useListContext,
  useGetList,
} from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  addHours,
  startOfHour,
  endOfDay,
  addDays,
  startOfToday,
} from 'date-fns';
import { Customer } from '../types';
import { useEffect } from 'react';

interface CardProviderFilter {
  cardProviderId: string | number;
}

const Aside = () => {
  const { filterValues, setFilters, displayedFilters } = useListContext();

  const { data: cardProviders, isLoading } = useGetList('cardProviders', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
  });

  const { data: cardTypes, isLoading: isCardTypesLoading } = useGetList(
    'cardTypes',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
    }
  );

  // Only fetch cardBankTypes when cardType is selected
  const { data: cardBankTypes, isLoading: isCardBankTypesLoading } = useGetList(
    'cardBankTypes',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
    }
  );

  // Clear cardBankType filter when cardType changes
  useEffect(() => {
    const selectedBankType = cardBankTypes?.find(
      (bankType: any) => bankType.id === filterValues?.cardBankType
    );

    if (
      filterValues?.cardBankType &&
      selectedBankType?.cardTypeId !== filterValues?.cardType
    ) {
      const newFilters = { ...filterValues };
      delete newFilters.cardBankType;
      setFilters(newFilters, displayedFilters);
    }
  }, [
    filterValues?.cardType,
    filterValues?.cardBankType,
    cardBankTypes,
    setFilters,
    displayedFilters,
  ]);

  // Filter cardBankTypes based on selected cardType
  const filteredCardBankTypes = cardBankTypes?.filter((bankType) => {
    return bankType.cardTypeId === filterValues?.cardType;
  });

  // Show Card Bank Types only when cardType is selected and filtered results exist
  const showCardBankTypes =
    !!filterValues?.cardType &&
    !isCardBankTypesLoading &&
    filteredCardBankTypes &&
    filteredCardBankTypes.length > 0;

  return (
    <Card
      sx={{
        order: -1,
        flex: '0 0 15em',
        mr: 2,
        mt: 6,
        alignSelf: 'flex-start',
        height: '85vh',
        overflowY: 'auto',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch hiddenLabel />
        <SavedQueriesList />

        {/* filter based on Card Type (CREDIT/CORPORATE/PREPAID) */}
        <FilterList label='Card Type' icon={<CheckCircleOutlineIcon />}>
          {!isCardTypesLoading &&
            cardTypes &&
            cardTypes.map((type) => {
              return (
                <FilterListItem
                  key={type.id}
                  label={type.name}
                  value={{ cardType: type.id }}
                />
              );
            })}
        </FilterList>

        {/* Show Card Bank Types only when cardType is selected */}
        {showCardBankTypes && (
          <FilterList label='Card Bank Type' icon={<CheckCircleOutlineIcon />}>
            {filteredCardBankTypes.map((bankType) => {
              return (
                <FilterListItem
                  key={bankType.id}
                  label={bankType.name}
                  value={{ cardBankType: bankType.id }}
                />
              );
            })}
          </FilterList>
        )}

        {/* Unlisted based filter */}
        <FilterList label='Active' icon={<CheckCircleOutlineIcon />}>
          <FilterListItem label='ra.boolean.true' value={{ unlisted: false }} />
          <FilterListItem label='ra.boolean.false' value={{ unlisted: true }} />
        </FilterList>

        {/* Card status codes:  1 - Pending, 2 - Successfully approved, 3 - Rejected */}
        <FilterList label='Card Status' icon={<CheckCircleOutlineIcon />}>
          <FilterListItem label='Pending' value={{ cardStatusId: 1 }} />
          <FilterListItem
            label='Successfully approved'
            value={{ cardStatusId: 2 }}
          />
          <FilterListItem label='Rejected' value={{ cardStatusId: 3 }} />
        </FilterList>

        {/* filter based on card provider */}
        <FilterList label='Card Provider' icon={<CheckCircleOutlineIcon />}>
          {!isLoading &&
            cardProviders &&
            cardProviders.map((provider) => {
              const filterValue = {
                cardProviderId: provider.dbId,
              };
              return (
                <FilterListItem
                  key={provider.id}
                  label={provider.name}
                  value={filterValue}
                />
              );
            })}
        </FilterList>

        {/* filter based on card creation date */}
        <FilterList label='Created' icon={<AccessTimeIcon />}>
          <FilterListItem
            label='Today'
            value={{
              createdAt_gte: startOfToday().toISOString(),
              createdAt_lte: endOfDay(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label='Yesterday'
            value={{
              createdAt_gte: startOfHour(
                addHours(endOfYesterday(), -24)
              ).toISOString(),
              createdAt_lte: endOfYesterday().toISOString(),
            }}
          />
          <FilterListItem
            label='This week'
            value={{
              createdAt_gte: startOfWeek(new Date()).toISOString(),
              createdAt_lte: endOfDay(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label='Last week'
            value={{
              createdAt_gte: startOfWeek(subWeeks(new Date(), 1)).toISOString(),
              createdAt_lte: endOfDay(
                addDays(startOfWeek(new Date()), -1)
              ).toISOString(),
            }}
          />
          <FilterListItem
            label='This month'
            value={{
              createdAt_gte: startOfMonth(new Date()).toISOString(),
              createdAt_lte: endOfDay(new Date()).toISOString(),
            }}
          />
          <FilterListItem
            label='Last month'
            value={{
              createdAt_gte: startOfMonth(
                subMonths(new Date(), 1)
              ).toISOString(),
              createdAt_lte: endOfDay(
                addDays(startOfMonth(new Date()), -1)
              ).toISOString(),
            }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default Aside;
