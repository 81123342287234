import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  SearchInput,
  ReferenceInput,
  AutocompleteInput,
  DateTimeInput,
  NumberInput,
} from 'react-admin';
import CustomerReferenceField from '../../customers/CustomerReferenceField';
import ApiUserLimitsAside from './ApiUserLimitsAside';
import { Customer } from '../../types';

// Add this interface if not already defined
interface User {
  id: string;
  name?: string;
  email?: string;
  // add other relevant user fields
}

const apiUserLimitFilters = [
  <SearchInput source='q' />,
  <ReferenceInput label='Customers' source='userId' reference='customers'>
    <AutocompleteInput
      optionText={(choice?: Customer) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice?.personalDetails?.firstName} ${choice?.personalDetails?.lastName}`
          : ''
      }
      sx={{ minWidth: 200 }}
    />
  </ReferenceInput>,
  <DateTimeInput label='Created After' source='createdAtGTE' />,
  <DateTimeInput label='Created Before' source='createdAtLTE' />,
  <NumberInput label='Hits Above' source='rateLimitGTE' />,
  <NumberInput label='Hits Below' source='rateLimitLTE' />,
  <DateTimeInput label='Date After' source='dateGTE' />,
  <DateTimeInput label='Date Before' source='dateLTE' />,
];

const ApiUserLimitsList = () => {
  return (
    <List
      filters={apiUserLimitFilters}
      resource='user/rateLimits'
      title='API User Limits'
      aside={<ApiUserLimitsAside />}
    >
      <Datagrid bulkActionButtons={false} rowClick='show'>
        <CustomerReferenceField
          link='show'
          // pagination={pagination}
        />

        {/* <TextField source="userId" label="User ID" /> */}
        <NumberField source='rateLimit' label='Hits' />
        <TextField source='date' />
        <DateField
          source='createdAt'
          showTime
          label='First Hit'
          options={{
            timeZone: 'Asia/Kolkata',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <DateField
          source='updatedAt'
          showTime
          label='Last Hit'
          options={{
            timeZone: 'Asia/Kolkata',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
      </Datagrid>
    </List>
  );
};

export default ApiUserLimitsList;
