import {
  Typography,
  Grid,
  Box,
  CardContent,
  Stack,
  Card,
  Button,
  IconButton,
} from '@mui/material';
import {
  AutocompleteInput,
  BooleanInput,
  DateField,
  Edit,
  ImageField,
  ImageInput,
  Labeled,
  PrevNextButtons,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useEditController,
  useNotify,
  useRefresh,
} from 'react-admin';
import { CustomerDetails } from '../orders/OrderEdit';
import { apiUrl } from '../constants';
import { useWatch, useController, useFormContext } from 'react-hook-form';
import { useEffect, useState, useRef } from 'react';
import useDecryptData from '../hooks/useDecryptData';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import * as markerjs2 from 'markerjs2';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';

interface BankInfo {
  bankId: string;
  name: string;
  logo: string;
  _id: string;
}

interface CardProviderInfo {
  cardProviderId: string;
  name: string;
  logo: string;
  _id: string;
}

const styles = {
  imageWrapper: {
    '.previews': {
      display: 'flex',
      justifyContent: 'center',
      div: {
        width: '100%',
      },
    },
    img: {
      borderRadius: 1,
      width: '100%',
      objectFit: 'cover',
    },
    '.image-marker-container': {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    '.image-marker-image': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
};

const updateImageToFormData = ({ formdata, data, imageKey, dataKey }: any) => {
  if (data[dataKey]?.rawFile) {
    return formdata.append(imageKey, data[dataKey]?.rawFile);
  } else if (data[dataKey] === null) {
    // Only append remove flag for the specific image
    return formdata.append(`${imageKey}Remove`, true);
  }
  return;
};

const CardEdit = () => {
  const [saveCounter, setSaveCounter] = useState(0);
  const refresh = useRefresh();
  const notify = useNotify();
  const { id } = useParams();
  const { refetch } = useEditController({ resource: 'cards', id });

  const handleSubmit = async (data: any, event: any) => {
    // if (!save) return;
    // event?.preventDefault();
    // await save({
    //   isActive: data.enabled,
    //   desc: data.desc,
    // });

    // if (!status) {
    //   notify("Please select a status", { type: "error" });
    //   return;
    // }
    // setLoading(true);

    const formdata = new FormData();
    formdata.append('name', data.name);
    formdata.append('last4Digit', data.last4Digit);
    formdata.append('cardBankType', data.cardBankType);
    formdata.append('cardBankName', data.cardBankName);
    formdata.append('cardNetworkName', data.cardNetworkName);
    formdata.append('cardType', data.cardType);
    formdata.append('unlisted', data.unlisted || false); // Default to false if not provided

    // Handle front image
    updateImageToFormData({
      formdata,
      data,
      imageKey: 'cardFrontImage',
      dataKey: 'frontImageUrl',
    });

    // Handle back image
    updateImageToFormData({
      formdata,
      data,
      imageKey: 'cardBackImage',
      dataKey: 'backImageUrl',
    });

    fetch(`${apiUrl}/cards/${data?.id}`, {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('auth') || '',
      },
      body: formdata,
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.code === 'MSG_0037') {
          notify('Card status was updated successfully', {
            type: 'success',
          });

          await refetch(); // Refetch the data after successful save
          await refresh();
          setSaveCounter((prev) => prev + 1);
        } else {
          notify('Something went wrong', { type: 'error' });
        }
      })
      .catch((e) => {
        notify('Something went wrong', { type: 'error' });
      });
  };

  return (
    <Edit redirect='false' mutationMode='pessimistic'>
      <SimpleForm onSubmit={handleSubmit} key={`${id}-${saveCounter}`}>
        <Stack
          sx={{
            flexDirection: {
              xs: 'column-reverse',
              sm: 'column-reverse',
              md: 'row',
            },
            gap: 2,
          }}
        >
          <Box>
            <PrevNextButtons
              linkType='edit'
              sort={{ field: 'createdAt', order: 'DESC' }}
            />
            <Card sx={{ mb: 10 }}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant='h6' gutterBottom>
                      Card Details
                    </Typography>

                    <Grid container sx={{ '& .ra-input': { width: '80%' } }}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Labeled source='createdAt' label='Submitted On'>
                          <DateField
                            source='createdAt'
                            showTime
                            options={{
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                              hour12: true,
                              hour: 'numeric',
                              minute: 'numeric',
                            }}
                          />
                        </Labeled>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Labeled source='id' label='Reference Id'>
                          <TextField source='id' />
                        </Labeled>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextInput label='Card Holder Name' source='name' />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextInput label='Last 4 Digits' source='last4Digit' />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ReferenceInput
                          label='Bank Name'
                          source='cardBankName'
                          reference='banks'
                          // filter={{ cardTypeId }}
                          // disabled
                        >
                          {/* <SelectInput optionValue='id' optionText='name' /> */}

                          <AutocompleteInput
                            optionText={(choice: BankInfo) => (
                              <Stack
                                direction='row'
                                alignItems='center'
                                spacing={1}
                              >
                                <img
                                  src={choice.logo}
                                  alt={choice.name}
                                  style={{ width: 30 }}
                                />
                                <Typography>{choice.name}</Typography>
                              </Stack>
                            )}
                            inputText={(choice: BankInfo) => choice.name}
                            optionValue='dbId'
                            fullWidth
                          />
                        </ReferenceInput>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceInput
                          label='Network Name'
                          source='cardNetworkName'
                          reference='cardProviders'

                          // filter={{ cardTypeId }}
                          // disabled
                        >
                          <AutocompleteInput
                            optionText={(choice: CardProviderInfo) => (
                              <Stack
                                direction='row'
                                alignItems='center'
                                spacing={1}
                              >
                                <img
                                  src={choice.logo}
                                  alt={choice.name}
                                  style={{ width: 30 }}
                                />
                                <Typography>{choice.name}</Typography>
                              </Stack>
                            )}
                            inputText={(choice: CardProviderInfo) =>
                              choice.name
                            }
                            optionValue='dbId'
                            fullWidth
                          />
                        </ReferenceInput>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceInput
                          label='Card Type'
                          source='cardType'
                          reference='cardTypes'
                          // disabled
                        >
                          <SelectInput optionValue='id' optionText='name' />
                        </ReferenceInput>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CardBankTypeSection />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box sx={{ maxWidth: '400px' }}>
                          <BooleanInput
                            label='Active'
                            source='unlisted'
                            parse={(v) => !v}
                            format={(v) => !v}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={5}>
                      <CardImage
                        keyName={'frontImageUrl'}
                        label='Front Image'
                      />
                      <CardImage keyName={'backImageUrl'} label='Back Image' />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Typography variant='h6' gutterBottom>
                      Customer
                    </Typography>
                    <ReferenceField
                      source='userId'
                      reference='customers'
                      link={false}
                    >
                      <CustomerDetails />
                    </ReferenceField>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Stack>
      </SimpleForm>
    </Edit>
  );
};

const CardBankTypeSection = () => {
  const cardTypeId = useWatch<{ cardType: string }>({ name: 'cardType' });

  return (
    <ReferenceInput
      label='Bank Type'
      source='cardBankType'
      reference='cardBankTypes'
      filter={{ cardTypeId }}
      // disabled
    >
      <SelectInput optionValue='id' optionText='name' />
    </ReferenceInput>
  );
};

const CardImage = ({ keyName, label }: any) => {
  const imageUrl = useWatch<{ [keyName: string]: string }>({
    name: keyName,
  }) as any;
  const { field } = useController({ name: keyName });
  const { setValue, formState } = useFormContext();
  const { data, dataLoading, decryptData, reset } = useDecryptData();
  const imageRef = useRef<HTMLImageElement>(null);
  const [blobUrl, setBlobUrl] = useState<string | null>(null);

  const handleRemoveImage = () => {
    setBlobUrl(null);
    setValue(keyName, null, {
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  // Add function to convert URL to File and create blob URL
  const urlToFile = async (url: string, filename: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], filename, { type: blob.type });
      const blobUrl = URL.createObjectURL(blob);
      setBlobUrl(blobUrl);
      return file;
    } catch (error) {
      console.error('Error converting URL to File:', error);
      return null;
    }
  };

  // Cleanup blob URL on unmount
  useEffect(() => {
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [blobUrl]);

  // Only decrypt when we have a string URL and no file upload in progress
  useEffect(() => {
    if (imageUrl && typeof imageUrl === 'string' && !(imageUrl as any).src) {
      decryptData(imageUrl);
    }
  }, [imageUrl]);

  // Handle API data changes
  useEffect(() => {
    const handleDataChange = async () => {
      if (
        typeof data === 'string' &&
        data.startsWith('http') &&
        imageUrl &&
        typeof imageUrl === 'string' &&
        !(imageUrl as any).src
      ) {
        const filename = data.split('/').pop() || 'image.jpg';
        const file = await urlToFile(data, filename);
        if (file) {
          const blobUrl = URL.createObjectURL(file);
          setBlobUrl(blobUrl);
          setValue(
            keyName,
            {
              rawFile: file,
              src: blobUrl,
              title: filename,
            }
            // {
            //   shouldDirty: true,
            //   shouldTouch: true,
            // }
          );
        }
      }
    };

    handleDataChange();
  }, [imageUrl, data, setValue, keyName]);

  // Get the correct image source
  const displayImageSrc = imageUrl?.src || blobUrl;

  const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const showMarkerArea = () => {
    if (imageRef.current) {
      const markerArea = new markerjs2.MarkerArea(imageRef.current);

      // Customize toolbar
      markerArea.settings.displayMode = 'popup';
      markerArea.settings.defaultColor = '#ff0000';
      markerArea.settings.defaultStrokeWidth = 3;
      markerArea.settings.defaultHighlightOpacity = 1;

      // Add only rectangle and marker tools
      markerArea.settings.defaultColorSet = [
        '#ff0000',
        '#0000ff',
        '#00ff00',
        '#ffff00',
      ];

      // Listen for when user finishes
      markerArea.addEventListener('render', (event) => {
        if (imageRef.current) {
          // Update the image display
          imageRef.current.src = event.dataUrl;

          // Create a new File object from the data URL
          const filename = imageUrl?.rawFile?.name || 'annotated-image.jpg';
          const newFile = dataURLtoFile(event.dataUrl, filename);

          // Update the form field with the new file
          field.onChange({
            rawFile: newFile,
            src: event.dataUrl,
            title: filename,
          });
        }
      });

      markerArea.show();
    }
  };

  return (
    <Grid item xs={12} sm={6} sx={styles.imageWrapper}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant='subtitle1' fontWeight='medium'>
          {label}
        </Typography>

        {displayImageSrc ? (
          <Box
            sx={{
              position: 'relative',
              border: '1px solid #e0e0e0',
              borderRadius: 1,
              padding: 1,
              backgroundColor: '#f8f8f8',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                zIndex: 1,
                display: 'flex',
                gap: 1,
              }}
            >
              <IconButton
                onClick={handleRemoveImage}
                sx={{
                  backgroundColor: 'white',
                  color: 'error.main',
                  '&:hover': {
                    backgroundColor: 'rgba(171, 171, 171, .3)',
                  },
                  boxShadow: 1,
                }}
                size='small'
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={showMarkerArea}
                sx={{
                  backgroundColor: 'white',
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'rgba(171, 171, 171, .3)',
                  },
                  boxShadow: 1,
                }}
                size='small'
              >
                <EditIcon />
              </IconButton>
            </Box>
            <img
              ref={imageRef}
              src={displayImageSrc}
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '400px',
                objectFit: 'contain',
                borderRadius: '4px',
              }}
              alt={label}
            />
          </Box>
        ) : null}

        <ImageInput
          source={keyName}
          label=''
          accept='image/*'
          onRemove={handleRemoveImage}
          sx={{
            '& .RaFileInput-dropZone': {
              border: '2px dashed #e0e0e0',
              borderRadius: 2,
              padding: 3,
              backgroundColor: '#fafafa',
              '&:hover': {
                borderColor: 'primary.main',
                backgroundColor: '#f0f7ff',
              },
            },
          }}
        >
          <ImageField source={keyName} />
        </ImageInput>
      </Box>
    </Grid>
  );
};
export default CardEdit;
