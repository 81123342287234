import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
} from 'react-admin';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
  addHours,
  startOfHour,
  endOfDay,
  addDays,
  startOfToday,
} from 'date-fns';

const Aside = () => (
  <Card
    sx={{
      order: -1,
      flex: '0 0 15em',
      mr: 2,
      mt: 6,
      alignSelf: 'flex-start',
      height: '85vh',
      overflowY: 'auto',
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterLiveSearch hiddenLabel />
      <SavedQueriesList />
      <FilterList label='Billpay Status' icon={<CheckCircleOutlineIcon />}>
        <FilterListItem
          label='Success'
          value={{
            billPayStatus: 'Success',
          }}
        />
        <FilterListItem
          label='Incomplete'
          value={{
            billPayStatus: 'INITIATED',
          }}
        />
        <FilterListItem
          label='Failed'
          value={{
            billPayStatus: 'FAILED',
          }}
        />
        <FilterListItem
          label='Refunded'
          value={{
            billPayStatus: 'REFUNDED',
          }}
        />
      </FilterList>
    </CardContent>
  </Card>
);

export default Aside;
