import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  useCreateController,
  FormDataConsumer,
  AutocompleteInput,
} from 'react-admin';
import { useState } from 'react';
import { TextField, Stack, Typography, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

interface BankInfo {
  dbId: string;
  name: string;
  logo: string;
}

interface CardProviderInfo {
  dbId: string;
  name: string;
  logo: string;
}

const BinCreate = () => {
  const { setValue, watch, getValues } = useForm();
  const [showOtherFields, setShowOtherFields] = useState(false);
  const [binError, setBinError] = useState('');

  const handleBinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // Only allow numbers and limit to 6 characters
    if (value.length > 6) {
      setBinError('BIN must be exactly 6 digits');
      return;
    }

    if (!/^\d*$/.test(value)) {
      setBinError('Only numbers are allowed');
      return;
    }

    setValue('bin', value);

    if (value.length < 6) {
      setBinError('BIN must be exactly 6 digits');
    } else {
      setBinError('');
      validateBin(value);
    }
  };

  const validateBin = async (value: string) => {
    if (value && value.length === 6) {
      try {
        const response = await fetch(
          `https://test.paymadi.com/admin/v1/api/bin/${value}`,
          {
            headers: {
              Authorization: localStorage.getItem('auth') || '',
            },
          }
        );
        const data = await response.json();

        if (data.code === 'MSG_0047') {
          setShowOtherFields(true);
          setBinError('');
        } else {
          setShowOtherFields(false);
          setBinError('* Record already exists');
        }
      } catch (error) {
        setBinError('Error checking BIN');
      }
    } else {
      setBinError('');
      setShowOtherFields(false);
    }
  };

  const { save } = useCreateController();

  const handleSubmit = async (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();

    console.log(data);

    save({
      cardType: data.cardTypeId,
      cardBankType: data.cardBankTypeId,
      cardProviderId: data.cardProviderId,
      bankId: data.bankId,
      bin_iin: getValues('bin'),
    });
  };

  return (
    <Create title='Create BIN'>
      <Grid container>
        <Grid item xs={12} md={4}>
          <SimpleForm
            onSubmit={handleSubmit}
            sx={{
              '& .MuiFormControl-root': { width: '100%', minWidth: '300px' },
              '& .ra-input': { width: '100%' },
            }}
          >
            <TextField
              label='BIN'
              value={watch('bin') || ''}
              onChange={handleBinChange}
              error={!!binError}
              helperText={binError}
              inputProps={{
                maxLength: 6,
                pattern: '[0-9]*',
              }}
              sx={{
                '& .MuiFormHelperText-root': {
                  color: '#d32f2f',
                  fontSize: '0.775rem',
                  marginTop: '4px',
                },
              }}
            />
            {showOtherFields && (
              <>
                <ReferenceInput source='cardTypeId' reference='cardTypes'>
                  <SelectInput optionText='name' validate={[required()]} />
                </ReferenceInput>
                <FormDataConsumer>
                  {({ formData }) => (
                    <ReferenceInput
                      source='cardBankTypeId'
                      reference='cardBankTypes'
                      filter={{ cardTypeId: formData.cardTypeId }}
                      key={formData.cardTypeId}
                    >
                      <SelectInput
                        optionText='name'
                        validate={[required()]}
                        disabled={!formData.cardTypeId}
                      />
                    </ReferenceInput>
                  )}
                </FormDataConsumer>
                <ReferenceInput
                  source='cardProviderId'
                  reference='cardProviders'
                >
                  <AutocompleteInput
                    optionText={(choice: CardProviderInfo) => (
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <img
                          src={choice.logo}
                          alt={choice.name}
                          style={{ width: 30 }}
                        />
                        <Typography>{choice.name}</Typography>
                      </Stack>
                    )}
                    inputText={(choice: CardProviderInfo) => choice.name}
                    optionValue='dbId'
                    validate={[required()]}
                  />
                </ReferenceInput>
                <ReferenceInput source='bankId' reference='banks'>
                  <AutocompleteInput
                    optionText={(choice: BankInfo) => (
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <img
                          src={choice.logo}
                          alt={choice.name}
                          style={{ width: 30 }}
                        />
                        <Typography>{choice.name}</Typography>
                      </Stack>
                    )}
                    inputText={(choice: BankInfo) => choice.name}
                    optionValue='dbId'
                    validate={[required()]}
                  />
                </ReferenceInput>
              </>
            )}
          </SimpleForm>
        </Grid>
      </Grid>
    </Create>
  );
};

export default BinCreate;
