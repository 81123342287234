import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SpeedIcon from '@mui/icons-material/Speed';
import PersonIcon from '@mui/icons-material/Person';
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
} from 'react-admin';
import {
  startOfWeek,
  subWeeks,
  startOfMonth,
  startOfToday,
  endOfToday,
  startOfDay,
} from 'date-fns';

const ApiUserLimitsAside = () => (
  <Card
    sx={{
      order: -1,
      flex: '0 0 15em',
      mr: 2,
      mt: 6,
      alignSelf: 'flex-start',
      height: '85vh',
      overflowY: 'auto',
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterLiveSearch source='userId' hiddenLabel />
      <SavedQueriesList />

      {/* <FilterList label='Customer' icon={<PersonIcon />}>
        <FilterListItem
          label='Search by Customer'
          value={{
            userId: true,
          }}
        />
      </FilterList> */}

      <FilterList label='API Hits' icon={<SpeedIcon />}>
        <FilterListItem
          label='Below 10'
          value={{
            rateLimitLTE: 10,
            rateLimitGTE: undefined,
          }}
        />
        <FilterListItem
          label='Below 50'
          value={{
            rateLimitLTE: 50,
            rateLimitGTE: undefined,
          }}
        />
        <FilterListItem
          label='Below 100'
          value={{
            rateLimitLTE: 100,
            rateLimitGTE: undefined,
          }}
        />
        <FilterListItem
          label='Above 100'
          value={{
            rateLimitLTE: undefined,
            rateLimitGTE: 100,
          }}
        />
      </FilterList>

      <FilterList label='Date Range' icon={<AccessTimeIcon />}>
        <FilterListItem
          label='Today'
          value={{
            dateGTE: startOfToday().toISOString(),
            dateLTE: endOfToday().toISOString(),
          }}
        />
        <FilterListItem
          label='This Week'
          value={{
            dateGTE: startOfWeek(new Date()).toISOString(),
            dateLTE: undefined,
          }}
        />
        <FilterListItem
          label='Last Week'
          value={{
            dateGTE: subWeeks(startOfWeek(new Date()), 1).toISOString(),
            dateLTE: startOfWeek(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label='This Month'
          value={{
            dateGTE: startOfMonth(new Date()).toISOString(),
            dateLTE: undefined,
          }}
        />
      </FilterList>

      <FilterList label='Time of Day' icon={<AccessTimeIcon />}>
        <FilterListItem
          label='First Half (12AM - 12PM)'
          value={{
            createdAtGTE: startOfDay(new Date()).toISOString(),
            createdAtLTE: new Date(
              new Date().setHours(12, 0, 0, 0)
            ).toISOString(),
          }}
        />
        <FilterListItem
          label='Second Half (12PM - 12AM)'
          value={{
            createdAtGTE: new Date(
              new Date().setHours(12, 0, 0, 0)
            ).toISOString(),
            createdAtLTE: endOfToday().toISOString(),
          }}
        />
      </FilterList>
    </CardContent>
  </Card>
);

export default ApiUserLimitsAside;
