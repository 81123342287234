import { SearchInput, SelectInput, useGetList } from 'react-admin';
import { ReactElement } from 'react';

export const useGetFilters = () => {
  const { data: _settlementTypes = [] } = useGetList('settlementTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const settlementTypes = Array.from(
    new Set(_settlementTypes.map((st) => st.name))
  ).map((name) => _settlementTypes.find((st) => st.name === name));

  const { data: rangeTypes = [] } = useGetList('rangeTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: cardTypes = [] } = useGetList('cardTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: _cardBankTypes = [] } = useGetList('cardBankTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const cardBankTypes = Array.from(
    new Set(_cardBankTypes.map((st) => st.name))
  ).map((name) => _cardBankTypes.find((st) => st.name === name));

  const { data: paymentTypes = [] } = useGetList('paymentTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: _merchantCodes = [] } = useGetList('merchantCodes', {
    pagination: { page: 1, perPage: 100 },
  });

  // Transform merchant codes to include both id and display value
  const merchantCodes = _merchantCodes.map((mc: any) => ({
    id: mc.id,
    code: mc.merchantId || mc.id,
    details: mc.details || mc.merchantId || mc.id
  }));

  return {
    settlementTypes,
    rangeTypes,
    cardTypes,
    cardBankTypes,
    paymentTypes,
    merchantCodes,
  };
};

interface FilterProps {
  settlementTypes: any[];
  rangeTypes: any[];
  cardTypes: any[];
  cardBankTypes: any[];
  paymentTypes: any[];
  merchantCodes: any[];
}

export const orderFilters = ({
  settlementTypes = [],
  rangeTypes = [],
  cardTypes = [],
  cardBankTypes = [],
  paymentTypes = [],
  merchantCodes = [],
}: FilterProps): ReactElement[] => {
  const filters: ReactElement[] = [];

  // Add search input
  filters.push(<SearchInput key="search" source="q" alwaysOn />);

  // Add settlement types filter
  if (settlementTypes?.length > 0) {
    filters.push(
      <SelectInput
        key="settlementType"
        source="settlementTypeId"
        choices={settlementTypes}
        optionText="description"
        optionValue="settlementTypeId"
      />
    );
  }

  // Add range types filter
  if (rangeTypes?.length > 0) {
    filters.push(
      <SelectInput
        key="rangeType"
        source="rangeTypeId"
        choices={rangeTypes}
        optionText="desc"
        optionValue="id"
      />
    );
  }

  // Add card types filter
  if (cardTypes?.length > 0) {
    filters.push(
      <SelectInput
        key="cardType"
        source="cardTypeId"
        choices={cardTypes}
        optionText="name"
        optionValue="id"
      />
    );
  }

  // Add card bank types filter
  if (cardBankTypes?.length > 0) {
    filters.push(
      <SelectInput
        key="cardBankType"
        source="cardBankTypeId"
        choices={cardBankTypes}
        optionText="name"
        optionValue="id"
      />
    );
  }

  // Add payment types filter
  if (paymentTypes?.length > 0) {
    filters.push(
      <SelectInput
        key="paymentType"
        source="paymentTypeId"
        choices={paymentTypes}
        optionText="desc"
        optionValue="id"
      />
    );
  }

  return filters;
};
