import BinCreate from './BinCreate';
import BinEdit from './BinEdit';
import BinList from './BinList';

const resource = {
  list: BinList,
  edit: BinEdit,
  create: BinCreate,
};

export default resource;
