import { ReferenceInput, SelectInput, TextInput } from 'react-admin';

export const BinFilters = [
  <TextInput source='bin' />,
  <ReferenceInput source='cardTypeId' reference='cardTypes'>
    <SelectInput optionText='name' />
  </ReferenceInput>,
  <ReferenceInput source='cardBankTypeId' reference='cardBankTypes'>
    <SelectInput optionText='name' />
  </ReferenceInput>,
  <ReferenceInput source='cardProviderId' reference='cardProviders'>
    <SelectInput optionText='name' optionValue='cardProviderId' />
  </ReferenceInput>,
  <ReferenceInput source='bankId' reference='banks'>
    <SelectInput optionText='name' />
  </ReferenceInput>,
];
