import {
  Collapse,
  IconButton,
  IconButtonProps,
  Stack,
  Typography,
  styled,
  Card,
  Box,
} from '@mui/material';
import useCardInformation from '../hooks/useCardInformation';
import { useEffect, useState, useMemo, useCallback } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { isEmpty } from 'lodash';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  height: 220,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 10,
  position: 'relative',
  border: '1px solid rgba(0, 0, 0, 0.2)',
  padding: '16px 20px',
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
}));

const CardChip = styled(Box)(({ theme }) => ({
  width: 40,
  height: 30,
  background: 'linear-gradient(135deg, #FFD700 0%, #FFC000 50%, #FFD700 100%)',
  borderRadius: 3,
  marginTop: -2,
  marginLeft: 10,
  position: 'relative',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(255, 215, 0, 0.5)',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `
      linear-gradient(90deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(255, 255, 255, 0.1) 100%
      )
    `,
    opacity: 0.9,
  },
  // EMV contact points with rounded edges
  '& > div': {
    position: 'absolute',
    zIndex: 2,
    background: '#FFD700',
    border: '1px solid rgba(255, 215, 0, 0.8)',
    borderRadius: '30%',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background:
        'linear-gradient(135deg, rgba(255,255,255,0.4) 0%, transparent 100%)',
      borderRadius: 'inherit',
    },
    '&:nth-of-type(1)': {
      // Top left
      top: '12%',
      left: '10%',
      width: '35%',
      height: '28%',
    },
    '&:nth-of-type(2)': {
      // Top right
      top: '12%',
      right: '10%',
      width: '35%',
      height: '28%',
    },
    '&:nth-of-type(3)': {
      // Middle left
      top: '43%',
      left: '10%',
      width: '35%',
      height: '28%',
    },
    '&:nth-of-type(4)': {
      // Middle right
      top: '43%',
      right: '10%',
      width: '35%',
      height: '28%',
    },
    '&:nth-of-type(5)': {
      // Bottom left
      bottom: '12%',
      left: '10%',
      width: '35%',
      height: '28%',
    },
    '&:nth-of-type(6)': {
      // Bottom right
      bottom: '12%',
      right: '10%',
      width: '35%',
      height: '28%',
    },
  },
}));

const HiddenNumberContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 3,
  backgroundColor: 'rgba(0, 79, 146, 0.2)',
  borderRadius: 8,
  padding: '2px 18px',
  justifyContent: 'center',
  marginLeft: -10,
}));

interface CardInformation {
  id: string;
  name: string;
  last4Digit: string;
  userId: string;
  frontImageUrl: string;
  bin?: string;
  last4?: string;
  type?: string;
  international?: boolean;
  entity?: string;
  issuer?: string;
  network?: string;
  emi?: boolean;
  sub_type?: string;
  bankInfo?: {
    logo?: string;
    name?: string;
  };
  cardProvidersInfo?: {
    logo?: string;
    name?: string;
  };
}

interface CardInformationProps {
  selectedCardId: string;
  forceExpanded?: boolean;
}

const CardInformation = ({
  selectedCardId,
  forceExpanded,
}: CardInformationProps) => {
  const [expanded, setExpanded] = useState(false);
  const {
    fetchCardInformation,
    cardInformation,
    cardInformationLoading,
    reset,
  } = useCardInformation();

  const handleExpandClick = useCallback(() => {
    if (!expanded) {
      fetchCardInformation(selectedCardId);
      // Set expanded immediately to show loading state
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [expanded, selectedCardId, fetchCardInformation]);

  // Reset when card changes
  useEffect(() => {
    setExpanded(false);
    reset();
  }, [selectedCardId, reset]);

  // If forceExpanded is true, fetch card info and keep expanded
  useEffect(() => {
    if (forceExpanded) {
      fetchCardInformation(selectedCardId);
      setExpanded(true);
    }
  }, [forceExpanded, selectedCardId, fetchCardInformation]);

  const cardData = useMemo(() => {
    // selectedCardId can be null, so we need to check if cardInformation is null

    return {
      name: cardInformation?.name || 'NO CARD FOUND',
      bin: cardInformation?.bin || '000000',
      last4Digit: cardInformation?.last4Digit || '0000',
      bankLogo: cardInformation?.bankInfo?.logo || null,
      cardProviderLogo: cardInformation?.cardProvidersInfo?.logo || null,
      cardStatusId: 2,
      bankName: cardInformation?.bankInfo?.name || 'N/A',
      cardProviderName: cardInformation?.cardProvidersInfo?.name || 'N/A',
    };
  }, [cardInformation]);

  return (
    <Stack mt={4} alignItems='center' width='100%'>
      <Stack direction='row' alignItems='center' width='95%' mb={2}>
        {!forceExpanded && (
          <Typography variant='h6'>Card Information</Typography>
        )}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label='show more'
          disabled={cardInformationLoading}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Stack>

      <Collapse
        in={expanded}
        timeout='auto'
        unmountOnExit
        sx={{ width: '100%' }}
      >
        <Stack alignItems='center' width='100%'>
          {cardInformationLoading ? (
            <Typography>Loading card information...</Typography>
          ) : (
            <StyledCard>
              <Box sx={{ alignSelf: 'flex-end', mt: 0.5 }}>
                {cardData?.bankLogo ? (
                  <img height={28} src={cardData.bankLogo} alt='bank logo' />
                ) : (
                  <CreditCardIcon sx={{ fontSize: 28 }} />
                )}
              </Box>

              <CardChip>
                <div /> {/* Top left */}
                <div /> {/* Top right */}
                <div /> {/* Middle left */}
                <div /> {/* Middle right */}
                <div /> {/* Bottom left */}
                <div /> {/* Bottom right */}
              </CardChip>

              <Box
                sx={{
                  border: '1px solid rgba(0, 79, 146, 0.3)',
                  borderRadius: 1.3,
                  p: 1,
                  height: 45,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <Typography variant='h6'>
                  {cardData?.bin?.slice(0, 4) || '0000'}
                </Typography>
                <Typography variant='h6'>
                  {cardData?.bin?.slice(4, 6)}
                </Typography>
                <HiddenNumberContainer>
                  {Array(2)
                    .fill('•')
                    .map((dot, i) => (
                      <Typography
                        key={i}
                        sx={{ fontSize: 22, color: 'rgba(0, 79, 146, 0.7)' }}
                      >
                        {dot}
                      </Typography>
                    ))}
                  <Typography
                    sx={{ fontSize: 22, color: 'rgba(0, 79, 146, 0.7)' }}
                  >
                    {' '}
                  </Typography>
                  {Array(4)
                    .fill('•')
                    .map((dot, i) => (
                      <Typography
                        key={i + 2}
                        sx={{ fontSize: 22, color: 'rgba(0, 79, 146, 0.7)' }}
                      >
                        {dot}
                      </Typography>
                    ))}
                </HiddenNumberContainer>
                <Typography variant='h6'>{cardData?.last4Digit}</Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 1,
                }}
              >
                <Typography variant='h6' sx={{ textTransform: 'uppercase' }}>
                  {cardData?.name}
                </Typography>
                <Box sx={{ alignSelf: 'flex-end' }}>
                  {cardData?.cardProviderLogo ? (
                    <img
                      height={20}
                      src={cardData?.cardProviderLogo}
                      alt='card provider logo'
                    />
                  ) : (
                    <Box sx={{ display: 'flex', gap: -1 }}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          borderRadius: 15,
                          bgcolor: 'red',
                        }}
                      />
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          borderRadius: 15,
                          bgcolor: 'orange',
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </StyledCard>
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default CardInformation;
