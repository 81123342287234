import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  SearchInput,
  NumberInput,
} from 'react-admin';
import ApiRateLimitsAside from './ApiRateLimitsAside';

const apiRateLimitFilters = [
  <SearchInput source='q' />,
  <NumberInput label='Rate Limit Above' source='rateLimitGTE' />,
  <NumberInput label='Rate Limit Below' source='rateLimitLTE' />,
];

const ApiRateLimitsList = () => {
  return (
    <List
      resource='apis/rateLimits'
      title='API Rate Limits'
      filters={apiRateLimitFilters}
      aside={<ApiRateLimitsAside />}
    >
      <Datagrid
        bulkActionButtons={false}
        rowClick='show'
        sx={{
          '& .RaDatagrid-headerCell': {
            width: '50%',
            textAlign: 'left',
          },
          '& .RaDatagrid-rowCell': {
            width: '50%',
            textAlign: 'left',
          },
        }}
      >
        <TextField source='apiPath' />
        <NumberField source='rateLimit' />
        {/* <DateField
          source="createdAt"
          showTime
          options={{
            timeZone: 'Asia/Kolkata',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        />
        <DateField
          source="updatedAt"
          showTime
          options={{
            timeZone: 'Asia/Kolkata',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
          }}
        /> */}
      </Datagrid>
    </List>
  );
};

export default ApiRateLimitsList;
