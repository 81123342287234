import { useState, useEffect } from 'react';
import { useStore } from 'react-admin';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
import { themes, ThemeName } from './themes';

const themeModeOptions = [
  {
    name: 'light',
    label: 'Light Mode',
    icon: LightModeIcon,
  },
  {
    name: 'dark',
    label: 'Dark Mode',
    icon: DarkModeIcon,
  },
  {
    name: 'system',
    label: 'Device default',
    icon: BrightnessMediumIcon,
  },
];

export const ThemeSwapper = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [themeName, setThemeName] = useStore<ThemeName>('themeName', 'soft');
  const [themeMode, setThemeMode] = useStore('theme', 'light');
  const [selectedMode, setSelectedMode] = useState('system');

  useEffect(() => {
    const storedMode = localStorage.getItem('RaStorepaymadi-admin.theme.mode');

    setSelectedMode(storedMode || 'light');

    if (storedMode === 'system') {
      const prefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      const systemTheme = prefersDark ? 'dark' : 'light';
      setThemeMode(systemTheme);
      localStorage.setItem('RaStorepaymadi-admin.theme.active', systemTheme);

      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (e) => {
          const newTheme = e.matches ? 'dark' : 'light';
          setThemeMode(newTheme);
          localStorage.setItem('RaStorepaymadi-admin.theme.active', newTheme);
        });
    } else {
      setThemeMode(storedMode || 'light');
      localStorage.setItem(
        'RaStorepaymadi-admin.theme.active',
        storedMode || 'light'
      );
    }
  }, []);

  // Update body background color based on main-content
  useEffect(() => {
    const syncBodyBackground = () => {
      const mainContent = document.getElementById('main-content');
      if (mainContent) {
        // Add a small delay to ensure theme has been applied
        setTimeout(() => {
          const mainContentBgColor =
            window.getComputedStyle(mainContent).backgroundColor;
          document.body.style.backgroundColor = mainContentBgColor;
        }, 0);
      }
    };

    // Initial sync
    syncBodyBackground();

    // Create observer to watch for background-color changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          syncBodyBackground();
        }
      });
    });

    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      observer.observe(mainContent, {
        attributes: true,
        attributeFilter: ['style'],
      });
    }

    // Cleanup
    return () => {
      observer.disconnect();
    };
  }, [themeMode, themeName]); // Add dependencies to re-run effect when theme changes

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleThemeChange = (newTheme: ThemeName) => {
    setThemeName(newTheme);
    // Trigger background sync after theme change
    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      setTimeout(() => {
        const mainContentBgColor =
          window.getComputedStyle(mainContent).backgroundColor;
        document.body.style.backgroundColor = mainContentBgColor;
      }, 0);
    }
    setAnchorEl(null);
  };

  const handleModeChange = (newMode: string) => {
    setSelectedMode(newMode);
    localStorage.setItem('RaStorepaymadi-admin.theme.mode', newMode);

    if (newMode === 'system') {
      const prefersDark = window.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;
      const systemTheme = prefersDark ? 'dark' : 'light';
      setThemeMode(systemTheme);
      localStorage.setItem('RaStorepaymadi-admin.theme.active', systemTheme);
    } else {
      setThemeMode(newMode);
      localStorage.setItem('RaStorepaymadi-admin.theme.active', newMode);
    }

    // Trigger background sync after mode change
    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      setTimeout(() => {
        const mainContentBgColor =
          window.getComputedStyle(mainContent).backgroundColor;
        document.body.style.backgroundColor = mainContentBgColor;
      }, 0);
    }
    setAnchorEl(null);
  };

  const getCurrentThemeIcon = () => {
    const activeTheme =
      localStorage.getItem('RaStorepaymadi-admin.theme.active') || themeMode;
    const ModeIcon =
      themeModeOptions.find((opt) => opt.name === activeTheme)?.icon ||
      LightModeIcon;
    return <ModeIcon fontSize='small' />;
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size='small'
        aria-controls={open ? 'theme-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        {getCurrentThemeIcon()}
      </IconButton>

      <Menu
        id='theme-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            mt: 1,
            minWidth: 200,
            borderRadius: 1,
          },
        }}
      >
        {/* Theme Mode Options */}
        {themeModeOptions.map(({ name, label, icon: Icon }) => (
          <MenuItem
            key={name}
            onClick={() => handleModeChange(name)}
            selected={name === selectedMode}
          >
            <ListItemIcon>
              <Icon fontSize='small' />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}

        <Divider />

        {/* Theme Options */}
        {themes.map(({ name }) => (
          <MenuItem
            key={name}
            onClick={() => handleThemeChange(name)}
            selected={name === themeName}
          >
            <ListItemText>
              {name.charAt(0).toUpperCase() + name.slice(1)} Theme
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
