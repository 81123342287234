import { Card, CardContent } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
} from 'react-admin';

const ApiRateLimitsAside = () => (
  <Card
    sx={{
      order: -1,
      flex: '0 0 15em',
      mr: 2,
      mt: 6,
      alignSelf: 'flex-start',
      height: '85vh',
      overflowY: 'auto',
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterLiveSearch source='apiPath' hiddenLabel />
      <SavedQueriesList />

      <FilterList label='Rate Limit Ranges' icon={<SpeedIcon />}>
        <FilterListItem
          label='0 - 100'
          value={{
            rateLimitLTE: 100,
            rateLimitGTE: 0,
          }}
        />
        <FilterListItem
          label='100 - 500'
          value={{
            rateLimitLTE: 500,
            rateLimitGTE: 100,
          }}
        />
        <FilterListItem
          label='500 - 1000'
          value={{
            rateLimitLTE: 1000,
            rateLimitGTE: 500,
          }}
        />
        <FilterListItem
          label='1000 - 2000'
          value={{
            rateLimitLTE: 2000,
            rateLimitGTE: 1000,
          }}
        />
        <FilterListItem
          label='Above 2000'
          value={{
            rateLimitLTE: undefined,
            rateLimitGTE: 2000,
          }}
        />
      </FilterList>
    </CardContent>
  </Card>
);

export default ApiRateLimitsAside;
