import React, { useState } from 'react';

import { createContext, useContext } from 'react';

// Create a context to share the state across components
export const NotesDrawerContext = createContext<{
  isOpen: boolean;
  isNewNoteOpen: boolean;
  openNotesDrawer: (isNewNoteOpen?: boolean) => void;
  closeNotesDrawer: () => void;
  toggleNotesDrawer: () => void;
  resetIsNewNoteOpen: () => void;
}>({
  isOpen: false,
  isNewNoteOpen: false,
  openNotesDrawer: () => {},
  closeNotesDrawer: () => {},
  toggleNotesDrawer: () => {},
  resetIsNewNoteOpen: () => {},
});

// Provider component
export const NotesDrawerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNewNoteOpen, setIsNewNoteOpen] = useState(false);

  const openNotesDrawer = (isNewNoteOpen = false) => {
    setIsOpen(true);
    setIsNewNoteOpen(isNewNoteOpen);
  };
  const closeNotesDrawer = () => {
    setIsOpen(false);
    setIsNewNoteOpen(false);
  };
  const toggleNotesDrawer = () => {
    setIsOpen(!isOpen);
    isOpen && setIsNewNoteOpen(false);
  };
  const resetIsNewNoteOpen = () => {
    setIsNewNoteOpen(false);
  };

  return (
    <NotesDrawerContext.Provider
      value={{
        isOpen,
        isNewNoteOpen,
        openNotesDrawer,
        closeNotesDrawer,
        toggleNotesDrawer,
        resetIsNewNoteOpen,
      }}
    >
      {children}
    </NotesDrawerContext.Provider>
  );
};

// Hook to use the drawer state
export const useNotesDrawer = () => {
  const context = useContext(NotesDrawerContext);
  if (!context) {
    throw new Error('useNotesDrawer must be used within a NotesDrawerProvider');
  }
  return context;
};
