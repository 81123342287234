import { Card, CardContent } from '@mui/material';
import {
  FilterList,
  FilterListItem,
  useGetList,
  SavedQueriesList,
  useListContext,
  FilterLiveSearch,
} from 'react-admin';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import { useEffect } from 'react';

const BinListAside = () => {
  const { filterValues, setFilters, displayedFilters } = useListContext();
  const { data: cardTypes = [] } = useGetList('cardTypes');
  const { data: cardBankTypes = [] } = useGetList('cardBankTypes');
  const { data: cardProviders = [] } = useGetList('cardProviders');
  const { data: banks = [] } = useGetList('banks', {
    pagination: { page: 1, perPage: 200 },
  });

  // Clear cardBankTypeId filter when cardTypeId changes
  useEffect(() => {
    const selectedBankType = cardBankTypes.find(
      (bankType: any) => bankType.id === filterValues?.cardBankTypeId
    );

    if (
      filterValues?.cardBankTypeId &&
      selectedBankType?.cardTypeId !== filterValues?.cardTypeId
    ) {
      const newFilters = { ...filterValues };
      delete newFilters.cardBankTypeId;
      setFilters(newFilters, displayedFilters);
    }
  }, [
    filterValues?.cardTypeId,
    filterValues?.cardBankTypeId,
    cardBankTypes,
    setFilters,
    displayedFilters,
  ]);

  // Filter cardBankTypes based on selected cardType
  const filteredCardBankTypes = cardBankTypes.filter((bankType: any) => {
    return bankType.cardTypeId === filterValues?.cardTypeId;
  });

  // Show Card Bank Types only when cardType is selected and filtered results exist
  const showCardBankTypes =
    !!filterValues?.cardTypeId && filteredCardBankTypes.length > 0;

  return (
    <Card
      sx={{
        order: -1,
        flex: '0 0 15em',
        mr: 2,
        mt: 6,
        alignSelf: 'flex-start',
        height: '85vh',
        overflowY: 'auto',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch hiddenLabel />
        <SavedQueriesList />

        <FilterList label='Card Types' icon={<CreditCardIcon />}>
          {cardTypes.map((cardType: any) => (
            <FilterListItem
              key={cardType.id}
              label={cardType.name}
              value={{ cardType: cardType.id }}
            />
          ))}
        </FilterList>

        {showCardBankTypes && (
          <FilterList label='Card Bank Types' icon={<AccountBalanceIcon />}>
            {filteredCardBankTypes.map((bankType: any) => (
              <FilterListItem
                key={bankType.id}
                label={bankType.name}
                value={{ cardBankTypeId: bankType.id }}
              />
            ))}
          </FilterList>
        )}

        <FilterList label='Card Providers' icon={<PaymentIcon />}>
          {cardProviders.map((provider: any) => (
            <FilterListItem
              key={provider.id}
              label={provider.name}
              value={{ cardProviderId: provider.dbId }}
            />
          ))}
        </FilterList>

        <FilterList label='Banks' icon={<AccountBalanceIcon />}>
          {banks.map((bank: any) => (
            <FilterListItem
              key={bank.id}
              label={bank.name}
              value={{ bankId: bank.dbId }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default BinListAside;
