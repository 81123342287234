import { Grid, Typography, TextField, Stack } from '@mui/material';
import { useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  useCreateController,
  useGetIdentity,
  FormDataConsumer,
  required,
  useGetList,
} from 'react-admin';
import { useForm, useWatch } from 'react-hook-form';

interface ServiceFeeCalculation {
  serviceFee: string;
  gst: string;
  isValid: boolean;
  serviceFeePercentage: number;
}

const calculateServiceFeeAndGST = (
  totalAmount: number,
  serviceFeePercentage: number
): ServiceFeeCalculation => {
  // Handle invalid or missing inputs
  if (!totalAmount || serviceFeePercentage < 0) {
    return {
      serviceFee: '0.00',
      gst: '0.00',
      isValid: false,
      serviceFeePercentage: 0,
    };
  }

  // Calculate total fee (service fee + GST) based on percentage
  const totalFee = (totalAmount * serviceFeePercentage) / 100;

  // Service fee is the base amount (totalFee / 1.18 to remove GST)
  const serviceFee = totalFee / 1.18;
  // GST is the difference between total fee and service fee
  const gst = totalFee - serviceFee;

  // Check if fee is valid
  if (totalFee >= totalAmount) {
    return {
      serviceFee: '0.00',
      gst: '0.00',
      isValid: false,
      serviceFeePercentage: 0,
    };
  }

  return {
    serviceFee: serviceFee.toFixed(2),
    gst: gst.toFixed(2),
    isValid: true,
    serviceFeePercentage,
  };
};

const OrderCreate = () => {
  const transactionModes = [
    { id: 'CASH', name: 'Cash' },
    { id: 'CARD', name: 'Card' },
    { id: 'UPI', name: 'UPI' },
  ];
  const { save } = useCreateController();
  const { setValue, getValues, watch } = useForm();
  const [error, setError] = useState<string | null>(null);
  const userData = useGetIdentity();
  const [customerNameError, setCustomerNameError] = useState('');
  const [customerMobileError, setCustomerMobileError] = useState('');
  const [serviceFeePercentage, setServiceFeePercentage] = useState(0);

  // Watch both values for validation
  const totalAmount = watch('totalAmount');
  const settlementAmount = watch('settlementAmount');

  const handleTotalAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setValue('totalAmount', value);
    setError(null);

    // Recalculate settlement amount based on service fee percentage
    const { serviceFee, gst, isValid } = calculateServiceFeeAndGST(
      value,
      serviceFeePercentage
    );
    const totalFee = parseFloat(serviceFee) + parseFloat(gst);
    const newSettlementAmount = isValid ? value - totalFee : value;
    setValue('settlementAmount', newSettlementAmount);
  };

  const handleServiceFeePercentageChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(e.target.value);
    if (value < 0 || value > 100) {
      setError('Service fee percentage must be between 0 and 100');
      return;
    }
    setServiceFeePercentage(value);
    setError(null);

    // Recalculate settlement amount
    const totalAmount = getValues('totalAmount');
    if (totalAmount) {
      const { serviceFee, gst, isValid } = calculateServiceFeeAndGST(
        totalAmount,
        value
      );
      const totalFee = parseFloat(serviceFee) + parseFloat(gst);
      const newSettlementAmount = isValid
        ? totalAmount - totalFee
        : totalAmount;
      setValue('settlementAmount', newSettlementAmount);
    }
  };

  const handleCustomerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue('customerName', value);

    if (!value.trim()) {
      setCustomerNameError('Customer name is required');
    } else if (value.length < 3) {
      setCustomerNameError('Name must be at least 3 characters');
    } else {
      setCustomerNameError('');
    }
  };

  const handleCustomerMobileChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setValue('customerMobile', value);

    if (value && !/^[6-9]\d{9}$/.test(value)) {
      setCustomerMobileError('Enter valid 10-digit mobile number');
    } else {
      setCustomerMobileError('');
    }
  };

  const getServiceFee = () => {
    const totalAmount = getValues('totalAmount');
    const settlementAmount = getValues('settlementAmount');
    const diff = totalAmount - settlementAmount;
    const gst = diff * 0.18;
    return { serviceFee: diff - gst, gst };
  };

  const handleSubmit = async (data: any, event: any) => {
    if (!save) return;
    event?.preventDefault();

    const {
      settlementType,
      payeeId,
      paymentType,
      cardInfo,
      transactionMode,
      customerName,
      customerMobile,
    } = data;
    const { serviceFee, gst } = getServiceFee();

    save({
      payeeId,
      userId: userData?.data?.userId,
      priceBreakUp: {
        feeBearer: 'user',
        serviceFeeAmount: serviceFee,
        gstAmount: gst,
        settlementAmount,
        totalAmount,
      },
      paymentType,
      settlementType,
      catgeoryType: 'EMPTY',
      offlineOrderinfo: {
        transactionMode,
        cardInfo,
        customerName: getValues('customerName'),
        customerMobile: getValues('customerMobile'),
      },
      serviceFee: serviceFeePercentage,
      gstFee: 18,
    });
  };
  console.log(getValues('customerName'), 'customerName');

  const { data: _settlementTypes = [] } = useGetList('settlementTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const settlementTypes = Array.from(
    new Set(_settlementTypes.map((st) => st.name))
  ).map((name) => _settlementTypes.find((st) => st.name === name));

  const renderServiceFeeInfo = () => {
    const totalAmount = getValues('totalAmount');
    const { serviceFee, gst, isValid } = calculateServiceFeeAndGST(
      totalAmount,
      serviceFeePercentage
    );

    if (!serviceFeePercentage) {
      return (
        <Typography
          color='text.secondary'
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            mt: 1,
            fontSize: '0.775rem',
            fontWeight: 400,
          }}
        >
          Auto-calculated based on Total Amount and Service Fee %
        </Typography>
      );
    }

    return (
      <Typography
        color={isValid ? 'text.primary' : 'error.main'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mt: 1,
          fontSize: isValid ? '0.875rem' : '0.775rem',
          fontWeight: isValid ? 500 : 400,
        }}
      >
        {isValid ? (
          <>
            <Stack>
              <Typography color='text.secondary' variant='body2'>
                Service Fee:
              </Typography>
              <Typography variant='h6'>
                ₹
                {parseFloat(serviceFee).toLocaleString('en-IN', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>
            <Stack>
              <Typography color='text.secondary' variant='body2'>
                GST (18%):
              </Typography>
              <Typography variant='h6'>
                ₹
                {parseFloat(gst).toLocaleString('en-IN', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>
            <Stack>
              <Typography color='text.secondary' variant='body2'>
                Total Service Fee:
              </Typography>
              <Typography variant='h6' color='success.main'>
                ₹
                {parseFloat(
                  Number(Number(serviceFee) + Number(gst)).toFixed(2)
                ).toLocaleString('en-IN', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>
          </>
        ) : (
          'Invalid amount entered'
        )}
      </Typography>
    );
  };

  return (
    <Create title='Create Order'>
      <SimpleForm onSubmit={handleSubmit}>
        <Grid container spacing={2} rowSpacing={-1} md={6}>
          <Grid item xs={12} sm={12}>
            <ReferenceInput
              source='payeeId'
              reference='payees'
              filter={{ userId: 'offline' }}
            >
              <AutocompleteInput
                fullWidth
                validate={[required()]}
                label='Select Payee'
                optionText={(choice) =>
                  choice?.payerName ? `${choice.payerName}` : ''
                }
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type='number'
              label='Total Amount'
              fullWidth
              value={totalAmount || ''}
              onChange={handleTotalAmountChange}
              error={totalAmount < settlementAmount}
              helperText={
                totalAmount < settlementAmount
                  ? 'Total amount must be greater than settlement amount'
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              type='number'
              label='Service Fee (%)'
              fullWidth
              value={serviceFeePercentage || ''}
              onChange={handleServiceFeePercentageChange}
              error={serviceFeePercentage < 0 || serviceFeePercentage > 100}
              helperText={
                serviceFeePercentage < 0 || serviceFeePercentage > 100
                  ? 'Service fee percentage must be between 0 and 100'
                  : ''
              }
              inputProps={{ min: 0, max: 100, step: 0.01 }}
            />
          </Grid>
          <Grid item xs={12} container spacing={2} alignItems='center' p={1}>
            <Grid item xs={12} sm={6}>
              <Typography color='text.secondary' variant='body2'>
                Settlement Amount:
              </Typography>
              <Typography variant='h6'>
                ₹
                {parseFloat(settlementAmount || '0').toLocaleString('en-IN', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {error ? (
                <Typography color='error' fontSize='0.775rem'>
                  {error}
                </Typography>
              ) : (
                <FormDataConsumer>
                  {() => renderServiceFeeInfo()}
                </FormDataConsumer>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Customer Name'
              fullWidth
              value={watch('customerName') || ''}
              onChange={handleCustomerNameChange}
              error={!!customerNameError}
              helperText={customerNameError}
              // required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Customer Mobile'
              fullWidth
              type='number'
              value={watch('customerMobile') || ''}
              onChange={handleCustomerMobileChange}
              error={!!customerMobileError}
              helperText={customerMobileError}
              inputProps={{
                maxLength: 10,
                pattern: '[6-9]{1}[0-9]{9}',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              source='transactionMode'
              choices={transactionModes}
              label='Transaction Mode'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ReferenceInput source='paymentType' reference='paymentTypes'>
              <SelectInput
                optionText='name'
                fullWidth
                optionValue='name'
                defaultValue={
                  useGetList('paymentTypes', {
                    pagination: { page: 1, perPage: 1 },
                    sort: { field: 'name', order: 'ASC' },
                  }).data?.[0]?.name
                }
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              key='settlementType'
              source='settlementType'
              choices={settlementTypes}
              optionText='name'
              optionValue='name'
              defaultValue={settlementTypes[0]?.name}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              source='cardInfo'
              label='Card Information'
              multiline
              rows={2}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default OrderCreate;
