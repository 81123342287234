import * as React from 'react';
import { AppBar, TitlePortal } from 'react-admin';
import { Box, useMediaQuery, Theme } from '@mui/material';

import Logo from './Logo';
import { AppBarToolbar } from './AppBarToolbar';

const CustomAppBar = () => {
  const isLargeEnough = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('sm')
  );
  return (
    <AppBar color='secondary' toolbar={<AppBarToolbar />}>
      {isLargeEnough && <Logo />}
      <Box sx={{ flex: 1 }}>
        <Box
          ml={2}
          sx={{
            maxWidth: isLargeEnough ? 'inherit' : '40vw',
            '& span': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              display: 'block',
            },
          }}
        >
          <TitlePortal />
        </Box>
      </Box>
      {/* {isLargeEnough && <Box component='span' sx={{ flex: 1 }} />} */}
    </AppBar>
  );
};

export default CustomAppBar;
